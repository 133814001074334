
import './App.css';

import { useContext ,useState} from 'react';

import   {HelmetProvider} from'react-helmet-async'; 
import  Helmet from'react-helmet'; 

import {
   createBrowserRouter,RouterProvider,Outlet,useNavigate
} from "react-router-dom";


import { AuthContext } from './context/authContext.js';
import Profile from './pages/dash/components/profile/Profil'
import Login from './pages/dash/Login';
import Register from './pages/dash/Register';
import Forgot from './pages/dash/Forgot';
import ResetPassword  from './pages/dash/ResetPass'
import ScrollUp from './components/scrollUp/ScrollUp'
import Home from './pages/bloge/home/Home'
import { Oops } from './components/oops/Oops';

import { About } from './pages/bloge/About/About';
import Navbar from './components/Navbar'
import Subsecribe from './components/subsecribe/Subsecribe'
import SingleArticle  from './pages/bloge/home/article/SingleArticle';
import Footer from './components/Footer'
import bigimg from './assets/bigimg.png';
import Article from './pages/bloge/home/article/Article';
import Articles from './pages/bloge/home/article/Articles';
import Contact from './pages/bloge/contact/Contact';
import Dash from './pages/dash/Dash';
import Sidebar from './pages/dash/components/sidebar/Sidebar';
import Accountside from './pages/dash/components/accountsection/Accountside';
import Addnewarticle from './pages/dash/components/AddNewArticle/Addnewarticle';
import Showarticle from './pages/dash/components/ShowArticle/Showarticle';
import HomeSearch from './pages/bloge/home/HomeSearch';

import ShowContact from './pages/dash/components/showContact/ShowContact';
import ShowSub from './pages/dash/components/showSub/ShowSub';

function ProtectedDashboardRoute({ children }) {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Check if the user is authenticated and is an admin
  if (!currentUser ) {
    // Redirect to a login page or display an access denied message
    navigate('/login');
    return null;
  }

  return children;
}

function App() {
  const Layout = () => {
    return(
     <>
       <HelmetProvider>
       <Helmet>
        <title>Bethought</title>
        <meta name="description" content="Bethought Description"  />
       
    <meta property="og:title" content="Bethought"  />
<meta property="og:description" content="Bethought Description"  />
   
       
      </Helmet>
      <Navbar/>
      <Outlet/>
      
      <Footer/>
      <ScrollUp/>
      </HelmetProvider>
     </>
    );
  }
  
  const Layout1 = () => {
    return(
     <>
     <div className='layout1'>
      <Sidebar/>
      <Outlet/>
      <Accountside/>
     
      </div>
     </>
    );
  }
    const Layout3 = () => {
    return(
     <>
      <Navbar/>
      <Outlet/>
     
     </>
    );
  }
    const Layout2 = () => {
    return(
     <>
      
      <Outlet/>
      
      <Footer/>
      <ScrollUp/>
     </>
    );
  }
  
  const router =  createBrowserRouter([
    {
      path:"/",
      element : <Layout />,
      children:[
        {
          path:"/",
          element :  <Home/>, 
          
        },
        
        {
          path:"/home",
          element :  <Home/>, 
        },
        {
          path:"/contact",
          element :  <Contact/>, 
        },
        {
          path:"/article/:id/:title",
          element :  <SingleArticle/>, 
        },
        {
          path:"/about",
          element :  <About/>, 
        },
       
        
      ]
      
    },{
  
    path:"/",
      element : <Layout1 />,
      children:[
        {
          path:"/dashboard",
          element : 
        
         <Login/>
           , 
        },
        {
          path:"/dashboard/addnewarticle",
          element :   <ProtectedDashboardRoute><Addnewarticle/></ProtectedDashboardRoute> , 
           
        },
        {
          path:"/dashboard/showarticle",
          element : <ProtectedDashboardRoute><Showarticle/></ProtectedDashboardRoute> , 
          
        }, {
          path:"/dashboard/ShowContact",
          element: <ProtectedDashboardRoute><ShowContact/></ProtectedDashboardRoute> , 
        } , {
          path:"/dashboard/profile/:userId",
          element: <ProtectedDashboardRoute><Profile/></ProtectedDashboardRoute> , 
        } , {
          path:"/dashboard/ShowSubscribres",
          element: <ProtectedDashboardRoute><ShowSub/></ProtectedDashboardRoute> , 
        } 
        
        
       
       ]},
     {
        path:"/",
        element : <Layout2 />,
        children:[
          {
            path:"/articles",
            element :  <Articles/>, 
            
          },]},
             {
            path:"/",
            element : <Layout3 />,
            children:[
              
              {
                path:"/Oops",
                element :  <Oops/>, 
              },
              
              
            ]
            
          },
    {
      path:"/login",
      element :  <Login/>, 
    },
    /*
    {
      path:"/register",
      element :  <Register/>, 
    },
    ,
    {
      path:"/Forgot",
      element :  <Forgot/>, 
    },
    {
      path: '/reset-password',
  element: <ResetPassword />,
    },*/
  
  ])
  

  return (
<div className='app'>




        <RouterProvider router={router}>
          {/* Your routes and components */}
          {/* ... */}
         
        </RouterProvider>
     
</div>
  )
}

export default App;
